.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    margin: calc(1rem + 3vw);
}

.header h1 strong {
    background-color: #333;
    color: #FEFEFE;
    padding-left: 1rem;
    padding-right: 1rem;
}