@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@1.207/distr/fira_code.css);
body {
    background-color: white;
    font-family: 'Fira Code', monospace;
    font-weight: 400;
    font-size: 16px;
    font-size: calc(12px + 0.5vw);
    line-height: 1.45;
    line-height: calc(12px + 1.05vw);
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin-bottom: 1.25em;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 2.75rem 0 1rem;
    font-weight: 400;
    line-height: 1.15;
}

h1 {
    margin-top: 0;
    font-size: 7.594em;
}

h2 {
    font-size: 5.063em;
}

h3 {
    font-size: 3.375em;
}

h4 {
    font-size: 2.25em;
}

h5 {
    font-size: 1.5em;
}

small {
    font-size: 0.667em;
}
.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    margin: calc(1rem + 3vw);
}

.header h1 strong {
    background-color: #333;
    color: #FEFEFE;
    padding-left: 1rem;
    padding-right: 1rem;
}
